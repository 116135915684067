.PhoneCarousel {
    //position: fixed;
    //top: 50%;
    //transform: translate(0, -50%);
    //-webkit-transform: translate(0, -50%);

    z-index: 9;
    width: 100%;

    &:before {
        content: "";
        position: absolute;
        top: -100%;
        left: 0;
        bottom: -100%;
        right: 0;
        filter: blur(20px);
        background-size: 100% 100%;
        background: no-repeat center url("./../../images/desktop/background_phone.svg");
    }
}

.slick-slider {
    margin: 0 50px;
    max-width: 1200px;
    @media screen and (max-width: 768px) {
        margin: 0 25px;
    }
    @media screen and (max-width: 490px) {
        margin: 0;
    }
    @media screen and (min-width: 1268px) {
        margin: 0 auto;
    }
}

.PhoneCarousel img {
    width: 172px !important;
    @media screen and (max-width: 490px) {
        width: 100px !important;
    }
}

.carousel-wrapper {
    position: relative;
}

.prev-button,
.next-button {
    position: fixed;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 1;
}

.prev-button {
    transform: translate(30%, -50%);
    left: 0;
}
.next-button {
    transform: translate(-30%, -50%);
    right: 0;
}

.slides {
    position: relative;
    .slick-prev,
    .slick-next {
        position: absolute;
        top: 50%;
        z-index: 100;
    }
    .slick-prev {
        left: 5%;
    }
    .slick-next {
        right: 5%;
    }
}

.slick-slider {
    .slick-arrow {
        height: 230px;
        width: 90px;

        &.slick-prev {
            background: no-repeat center #1d1d1d url("./../../images/desktop/left_btn_carousel.svg");
            background-size: contain;
            left: -50px;
            &:after,
            &:before {
                display: none;
            }
            @media screen and (max-width: 490px) {
                left: 0;
            }
        }
        &.slick-next {
            background: no-repeat center #1d1d1d url("./../../images/desktop/right_btn_carousel.svg");
            background-size: contain;
            right: -50px;
            &:after,
            &:before {
                display: none;
            }
            @media screen and (max-width: 490px) {
                right: 0;
            }
        }
        &.slick-disabled {
            display: none !important;
        }

        @media screen and (max-width: 490px) {
            height: 240px;
            width: 30px;
        }
    }
}
