.header-content
    .downloads
        display: flex
        justify-content: space-evenly
        align-items: flex-start
        padding: 30px 50px

        @media screen and (max-width: 490px)
            padding: 10px 25px

        @media screen and (max-width: 390px)
            padding: 30px 25px

        @media screen and (max-width: 768px)
            padding: 10px 25px

        @media screen and (min-width: 768px+1)
            display: none

        > div
            flex-basis: 50%
            text-align: center

            img
                max-width: 140px
                width: 100%

                @media screen and (max-width: 490px)
                    width: auto
                    max-width: none
                    height: 35px
        
        .bluefy-rectangle
            height: 34px
            width: 138px
            margin: 0 auto

        .container
            padding: 15px 0
            position: relative

            &:before
                content:""
                position: absolute
                height: 15px
                width: 0px
                border-left: 1px solid #fff
                top: 0px
                left: 50%
                right: 50%

            &:after
                content:""
                position: absolute
                height: 20px
                width: 0px
                border-left: 1px solid #fff
                bottom: -1px
                left: 50%
                right: 50%

            .bluefy-icon
                width: 30px
                height: 30px
                max-width: 30px

        .download-bluefy-paragraphe
            text-align: center
            margin: 0
            position: relative
            padding: 10px 0 0 
            
            @media screen and (max-width: 490px)
                font-size: 0.563rem
                line-height: 0.625rem

            &:before
                content:""
                position: absolute
                height: 0px
                width: 52px
                border-top: 1px solid #fff
                top: 0px
                left: 50%
                transform: translateX(-26px)

    .paragraphe-text
        @media screen and (max-width: 490px)
            padding: 0 65px
            margin: 0
            width: auto
            font-size: 0.625rem
            line-height: 0.875rem
            z-index: 1

    .small-bold-text
        @media screen and (max-width: 490px)
            font-size: 8px 
            line-height: 14px
            position: absolute
            bottom: -18%
            margin: 0
            left: 0
            right: 0
