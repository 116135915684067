$mobile-breakpoint: 490px;
$image-mobile-breakpoint: 390px;
$tablet-breakpoint: 768px;
$small-screen-breakpoint: 1024px;
$large-screen-breakpoint: 1268px;

@font-face {
    font-family: "Gotham Black";
    src: url(./../../fonts/gotham-black-0b52bc5e259f5cc079b29a9e6585b900.woff) format("woff");
    font-style: normal;
}
@font-face {
    font-family: "Gotham Bold";
    src: url(./../../fonts/gotham-bold-6737c42f9e2394e415fcd1518771df26.woff) format("woff");
    font-style: normal;
}
@font-face {
    font-family: "Gotham Medium";
    src: url(./../../fonts/gotham-medium-eec9d76d8a8436dd4528b1ebc87a68fe.woff) format("woff");
    font-style: normal;
}
@font-face {
    font-family: "Gotham Book";
    src: url(./../../fonts/gotham-book-c9d6342d60c821049b4a77146fcf7c0a.woff) format("woff");
}
@font-face {
    font-family: "Poster Trim";
    src: url(./../../fonts/trim-poster-09250a9602fd9a237330b3ed875800ae.woff) format("woff");
}

.desktop-layout {
    visibility: visible;
}

.mobile-layout {
    visibility: hidden;
    display: none;
}

.Home {
}

body {
    // position: absolute;
    // width: 100vw;
    // overflow-x: hidden;
}

.background {
    position: fixed;
    top: 0;
    left: 0;
    background-color: #1d1d1d;
    width: 100vw;
    height: 100%;
    z-index: 0;
}

.foreground-top {
    background: transparent url(/images/desktop/New-Orang-BG_top@2x.png);
    padding-bottom: 60px;
    margin-bottom: 350px;
    height: fit-content;
}

.foreground-bottom {
    background: url(/images/desktop/New_Blue-BG_bot.png);
    padding-bottom: 80px;
}

.foreground-top,
.foreground-bottom {
    background-repeat: no-repeat;
    background-size: 100% 100% !important;
}

.foreground-content {
    position: relative;
    width: 100%;
    height: 100%;

    display: inline-flex;
    display: flex;
    flex-direction: column;
    // gap: 25px;

    justify-content: unset;

    @media screen and (max-width: $small-screen-breakpoint) {
        grid-gap: 30px;
        gap: 30px;
        justify-content: unset;
    }

    @media screen and (max-width: $tablet-breakpoint) {
        grid-gap: 2%;
        gap: 2%;
        justify-content: unset;
    }

    @media screen and (max-width: 650px) {
        grid-gap: 10px;
        gap: 10px;
        justify-content: unset;
    }
}

.foreground-content > div,
.foreground-content > p {
    margin-top: 25px !important;
}

.page-title {
    margin-top: 70px;
    font-family: "Poster Trim", Helvetica, sans-serif;
    font-size: 3.375rem;
    margin-bottom: 10px;
    width: 100%;
    text-align: center;
    color: white !important;
    line-height: 3.438rem;

    img {
        height: 41px;
        vertical-align: baseline;
        padding: 0 10px 0 20px;
        @media screen and (max-width: $small-screen-breakpoint) {
            height: 41px;
        }

        @media screen and (max-width: 800px) {
            height: 34px;
        }

        @media screen and (max-width: $tablet-breakpoint) {
            height: 28px;
            padding: 0 10px 0 10px;
        }
    }

    .my-vuse-app-logo-mobile {
        display: none;
    }

    @media screen and (max-width: $small-screen-breakpoint) {
        margin-top: 5%;
        font-size: 50px;
    }

    @media screen and (max-width: 800px) {
        margin-top: 2%;
        font-size: 40px;
    }

    @media screen and (max-width: $tablet-breakpoint) {
        margin-top: 2%;
        font-size: 35px;
    }
}

.paragraphe-text {
    font-family: "Gotham Book", Helvetica, sans-serif;
    color: white;
    text-align: center;
    font-size: 0.875rem;
    padding: 0 25px;
    margin: 0 auto;
    max-width: 709px;
    line-height: 1.313rem;
}

.small-bold-text {
    color: white;
    text-align: center;
    font-size: 10px;
    font-weight: 600;
    @media screen and (max-width: 490px) {
        z-index: 10;
    }
}

.features-grid {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    padding-bottom: 100px;
}

.text-image-flex {
    width: 100%;
    display: flex;
}

#download-app-container {
    display: flex;
    // gap: 70px;
    .play-store-image {
        img {
            //394
            //118
            //width: 159px;
            height: 50px;
        }
    }

    @media screen and (max-width: 768px) {
        display: none;
    }

    .bluefy-image {
        width: 38px;
        position: relative;
        display: inline-block;
        vertical-align: middle;

        &:before {
            content: "";
            position: absolute;
            height: 22px;
            width: 0;
            border-left: 1px solid #fff;
            top: -23px;
            left: 50%;
            @media screen and (max-width: 1024px) {
                height: 100%;
                top: -100%;
            }
        }

        &:after {
            content: "";
            position: absolute;
            height: 0;
            width: 20px;
            border-top: 1px solid #fff;
            right: -20px;
            top: 50%;
        }
    }
}

#download-app-container .left-item,
#download-app-container .right-item {
    flex-basis: 100%;
    align-items: flex-start;
}
#download-app-container .left-item {
    display: flex;
    justify-content: flex-end;
    margin-right: 35px;
}

#download-app-container .right-item {
    display: flex;
    justify-content: flex-start;
    margin-left: 35px;
}

.side-by-side-close {
    display: flex;
    padding: 20px 0 0 75px;
    align-items: center;
    @media screen and (max-width: 390px) {
        margin: 0;
    }
}

.top-down {
    display: flex;
    flex-direction: column;
}

.play-store-image img {
    //width: 159px;
    height: 50px;
}

.install-on-linked-items-container .play-store-image img {
    height: 80px;
}

.bluefy-rectangle {
    width: 189px;
    border: 1px solid white;
    background-color: black;
    border-radius: 4px;
    height: 48px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    @media screen and (max-width: 490px) {
        font-size: 1.188rem;
        line-height: 1.063rem;
        height: 46px;
    }
}

.bluefy-rectangle span:first-child {
    font-size: 1.188rem;
    line-height: 1.063rem;
    font-family: "Poster Trim", Helvetica, sans-serif;
    width: 100%;
    display: inline-block;
    @media screen and (max-width: 490px) {
        font-size: 14px;
        line-height: 1.063rem;
    }
}

.bluefy-rectangle-text {
    font-family: "Gotham Book", Helvetica, sans-serif;
    font-size: 0.438rem;
    line-height: 0.563rem;
    color: #ffffff;
    text-align: center;
    opacity: 0.4;
    letter-spacing: 0.96px;
    @media screen and (max-width: 490px) {
        font-size: 5px;
        line-height: 0.563rem;
    }
}

.vertical-line {
    height: 18px;
    border-left: 1px solid white;
}

.bluefy-image img {
    position: relative;
    width: 38px;
}

.install-on-linked-items-container .bluefy-image img {
    width: 53px;
}

.download-bluefy-paragraphe {
    color: white;
    font-size: 0.813rem;
    max-width: 350px;
    line-height: 0.813rem;
    display: inline-block;
    vertical-align: middle;
    margin: 0;
    padding: 0 5px 0 30px;
    position: relative;

    &:before {
        content: "";
        position: absolute;
        height: 20px;
        width: 0;
        border-left: 1px solid #fff;
        top: 50%;
        bottom: 0;
        left: 20px;
        transform: translateY(-50%);
    }
}

.play-store-image,
.bluefy-rectangle {
    font-size: 1.188rem;
    font-weight: 600;
    color: white;
    position: relative;
    z-index: 1;
    @media screen and (max-width: 490px) {
        font-size: 0.938rem;
    }
}

.foreground-top,
.foreground-bottom {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    z-index: 1;
}

.title-text {
    position: fixed;
    top: 0;
    left: 0;
    font-size: 40px;
    color: white !important;
    font-weight: 600;
}

/* Foreground 2 */
.install-app-side-by-side {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: fit-content;
    justify-content: center;
    margin-top: 145px;
    gap: 150px;
}

.install-on-top-down {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.install-on-title {
    font-size: 1.875rem;
    font-weight: 600;
    color: white;
    text-align: center;
    font-family: "Poster Trim", Helvetica, sans-serif;
    line-height: 1.625rem;

    @media screen and (max-width: 490px) {
        font-size: 1.563rem;
        line-height: 1.625rem;
        margin-bottom: 20px;
    }
}

.install-on-description {
    text-align: center;
    color: white;
    font-size: 0.875rem;
    max-width: 360px;
    line-height: 1.125rem;
    margin-bottom: 32px;

    @media screen and (max-width: 490px) {
        font-size: 0.625rem;
        line-height: 0.875rem;
        margin-bottom: 20px;
        margin-top: 0;
        min-height: auto;
    }
}

.install-on-linked-items-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

#my-vuse-com-bordered-container {
    border: 1px solid white;
    background-color: black;
    color: white;
    border-radius: 19px;
    font-size: 1.063rem;
    font-weight: 600;
    width: 203px;
    height: 37px;
    text-align: center;
    line-height: 37px;
    font-family: "Poster Trim", Helvetica, sans-serif;
}

.my-vuse-icon img {
    width: 123px;
    height: auto;
}

// *************************************** MEDIA QUERY MOBILE

@media screen and (max-width: 490px) {
    .mobile-layout {
        visibility: visible;
        display: unset;
    }

    .empty-space {
        height: fit-content;
        width: 100%;
    }

    .page-title {
        font-size: 2.063rem;
        padding: 0 25px;
        margin: 45px 0 0;
        line-height: 2rem;

        img {
            height: 40px;
        }

        .bluefy-icon {
            display: none;
        }

        span {
            display: none;
        }

        .my-vuse-app-logo-mobile {
            display: inline;
            height: 42px;
            margin-top: 15px;
        }
    }

    .paragraphe-text {
        font-size: 10px;
        padding: 10px 30px;
        margin-top: 0;
    }

    .foreground-top {
        background: url(../../images/mobile/mobile-orange-bg@3x.png);
        padding-bottom: 60px;
        margin-bottom: 350px;
        height: fit-content;
    }

    .foreground-bottom {
        background: url(../../images/mobile/Blue-bg_mobile@3x.png);

        padding-bottom: 80px;
    }

    .features-grid {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 32px;
    }

    .install-app-side-by-side {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: fit-content;
        justify-content: center;
        margin-top: 145px;
        gap: 150px;
    }

    #download-app-container {
        gap: 25px;
    }

    .play-store-image img {
        width: 159px;
        height: 48px;
    }

    .bluefy-rectangle {
        width: 138px;
    }
}
