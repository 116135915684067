$mobile-breakpoint: 490px
$image-mobile-breakpoint: 390px
$tablet-breakpoint: 768px
$small-screen-breakpoint: 1024px
$large-screen-breakpoint: 1268px

html
    font-size: 16px

body
    width: 100%

    @media screen and (max-width: $mobile-breakpoint)
        background-color: #1d1d1d

.header
    background: no-repeat center top url(/images/desktop/New-Orang-BG_top@2x.png)
    height: fit-content
    background-size: cover
    z-index: 10
    display: grid
    position: relative

    &:after
        content: ''
        display: none
        background-color: #1d1d1d
        width: 100%
        position: absolute
        bottom: -50%
        height: 50%
        z-index: -11
        left: 0

    @media screen and (max-width: $large-screen-breakpoint)
        background-size: 100% 100%
        min-height: 1112px

    @media screen and (min-width: $large-screen-breakpoint+1)
        background-size: 100% 100%
        min-height: 1112px

    @media screen and (max-width: $small-screen-breakpoint)
        min-height: 1112px
        background-size: 100% 100%

    @media screen and (max-width: $tablet-breakpoint)
        background-size: 100% 100%
        background-size: cover

    @media screen and (max-width: $image-mobile-breakpoint)
        background: none

    @media screen and (max-width: $mobile-breakpoint)
        background: none

    .header-mobile-bg
        display: none
        flex-direction: column
        justify-content: center
        position: relative

        @media screen and (max-width: $mobile-breakpoint)
            background: no-repeat center url(/images/mobile/mobile-orange-bg@2x.png)
            background-size: 100% 100%
            min-height: 831px
            display: flex

        @media screen and (max-width: $image-mobile-breakpoint)
            background: no-repeat center url(/images/mobile/mobile-orange-bg@2x.png)
            background-size: contain
            min-height: 831px
            display: flex

        .FeatureBloc
            &:first-child
                @media screen and (max-width: $mobile-breakpoint)
                    margin-top: 20%

            @media screen and (max-width: $mobile-breakpoint)
                margin: 2% 0

    .header-content
        height: 100%
        position: relative

        .small-bold-text
            font-size: 0.5rem
            line-height: 0.875rem

        @media screen and (max-width: $mobile-breakpoint)
            min-height: 400px
            background-color: #000

            &:after
                content: ''
                position: absolute
                width: 100%
                height: 150px
                bottom: -150px
                background-color: #000
                z-index: 0

    .features-grid
        max-width: 1200px
        width: 100%
        margin: 0 auto

        @media screen and (max-width: $mobile-breakpoint)
            display: none

            
.block-3
    z-index: 9
    position: relative
    background-color: #1d1d1d
    text-align: center
    padding-top: 5%

    @media screen and (max-width: $mobile-breakpoint)
        min-height: 250px

    &:before
        content: ''
        background-color: #1d1d1d
        width: 100%
        position: absolute
        top: -50%
        height: 50%
        z-index: -11
        left: 0

    &:after
        content: ''
        background-color: #1d1d1d
        width: 100%
        position: absolute
        bottom: -50%
        height: 50%
        z-index: -11
        left: 0

        @media screen and (min-width: $large-screen-breakpoint)
            bottom: -100%
            height: 100%

    .small-bold-text
        display: none

        @media screen and (max-width: $mobile-breakpoint)
            font-size: 8px 
            line-height: 14px
            position: absolute
            bottom: -35%
            margin: 0
            left: 0
            right: 0
            display: block

.block-4
    background: no-repeat top transparent url(/images/desktop/Blue-bg-new@2x.png)
    min-height: 683px
    background-size: cover
    z-index: 10
    position: relative
    display: grid
    
    .desktop-content
        display: flex
        padding: 16% 0 5%
        flex-direction: row
        align-items: center
        
        .install-app-side-by-side
            > div
                &:last-child
                    padding-left: 5%

                &:first-child
                    padding-right: 5%

            .android-block
                .install-app-side-by-side
                    img
                        width: 197px

                .play-store-image
                    height: 50px

            .ios-block
                .vertical-line
                    &.vertical-line-2
                        margin-top: -3px
                        height: 31px


        @media screen and (max-width: $tablet-breakpoint)
            padding: 20% 0 5%

        @media screen and (max-width: $mobile-breakpoint)
            display: none

    .mobile-content
        display: none
        
        @media screen and (max-width: $mobile-breakpoint)
            display: flex
            flex-direction: column
            justify-content: center  

        .FeatureBloc
            &:first-child
                @media screen and (max-width: $mobile-breakpoint)
                    margin-top: 20%

            @media screen and (max-width: $mobile-breakpoint)
                margin: 2% 0

    .install-app-side-by-side
        margin:0
        gap: 0
            
    @media screen and (max-width: $mobile-breakpoint)
        background: no-repeat top transparent url(/images/mobile/Blue-bg_mobile@2x.png)
        background-size: 100% 100%
        display: grid
        height: fit-content
        min-height: 835px

    @media screen and (max-width: $image-mobile-breakpoint)
        background: no-repeat #000 transparent url(/images/mobile/Blue-bg_mobile@2x.png)
        min-height: 835px
        background-size: 100% 100%

.block-5
    display: none
    z-index: 0

    .install-on-top-down
        padding-top: 7%
        margin: 0 25px -25px 25px

    .android-block
        .play-store-image
            @media screen and (max-width: $mobile-breakpoint)
                height: 48px

    @media screen and (max-width: $mobile-breakpoint)
        min-height: auto
        background-color: #000
        position: relative
        display: block
        z-index: 9
        
        &:before
            content: ""
            background-color: #000
            width: 100%
            position: absolute
            top: -100%
            height: 100%

        &:after
            content: ""
            background-color: #000
            width: 100%
            position: absolute
            bottom: -100%
            height: 100%

.block-6
    display: none
    height: fit-content
    position: relative
    z-index: 10

    .install-on-top-down
        padding: 33% 20px 15%

    @media screen and (max-width: $mobile-breakpoint)
        background: no-repeat top transparent url(/images/mobile/2bd-background.svg)
        background-size: cover
        display: block

    @media screen and (max-width: $image-mobile-breakpoint)
        background: no-repeat top transparent url(/images/mobile/2bd-background.svg)
        background-size: cover
        display: block

    .ios-block 
        .vertical-line-2
            @media screen and (max-width: $mobile-breakpoint)
                height: 21px
                margin-top: -3px