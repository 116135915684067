.AgePopup {
    position: fixed;
    display: flex;
    justify-items: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    background-color: rgba(51, 51, 51, 0.55);
    z-index: 100;
}

.age-popup-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    padding-top: 40px;
    // padding: 40px 2.5rem 2.6rem 2.5rem;
    padding-bottom: 2.6rem;

    background-color: rgb(245, 247, 250);

    width: calc(100vw - 60px);
    max-width: 670px;
    max-height: 880px;

    box-shadow: 0 0 12px 2px rgb(0 0 0 / 35%);

    @media screen and (max-width: 690px) {
        padding-top: 40px;
        // padding-right: 2rem;
        // padding-left: 3rem;
        // padding: 40px 3rem 2.6rem 3rem;
    }

    // @media screen and (min-width: 768px) {
    //     padding-left: 70px;
    //     padding-right: 70px;
    // }

    img {
        filter: invert(1);
        margin-bottom: 25px;
    }
}

.age-popup-title {
    font-family: "Poster Trim", Helvetica, sans-serif;
    color: #1d1d1b;
    line-height: 1.1;
    font-weight: 600;
    font-size: 1.9rem;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 10px;
    margin-top: 0;

    max-width: 80%;

    @media screen and (max-width: 690px) {
        padding: 0 20px;
    }
}

.age-popup-subtitle {
    color: black;
    width: 95%;
    font-weight: 400;
    line-height: 1.5;
    font-size: 14px;
    text-align: center;

    margin: 0;
    margin-bottom: 1rem;

    @media screen and (max-width: 690px) {
        max-width: 80%;
    }
}

.notice-text {
    color: #2a2a2a;
    font-size: 12px;
    line-height: 1.4;
    text-align: center;

    @media screen and (max-width: 690px) {
        max-width: 80%;
    }
}

.button-container {
    display: flex;
    flex-direction: row;

    align-items: center;

    @media screen and (max-width: 690px) {
        flex-direction: column-reverse;
        width: 85%;
    }
}

.yellow-button,
.white-button {
    font-family: "Gotham Book", Helvetica, sans-serif;
    position: relative;
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;

    border-radius: 32px;
    padding: 0px 24px;
    cursor: pointer;
    text-align: center;
    transition: all 0.3s ease-in-out 0s;
    color: black;

    border: 2px solid #f8ab28;

    width: 190px;
    height: 40px;

    @media screen and (max-width: 690px) {
        height: 40px;
        width: 100%;
    }
}

.yellow-button {
    margin-left: 10px;
    margin-right: 65px;

    background-color: #f8ab28;

    @media screen and (max-width: 690px) {
        margin-left: auto;
        margin-right: auto;
        margin-top: 0;
    }
}

.white-button {
    margin-right: 10px;
    margin-left: 65px;
    background-color: white;

    @media screen and (max-width: 690px) {
        margin-left: auto;
        margin-right: auto;
        margin-top: 12px;
    }
}

.side-by-side {
    display: flex;
    flex-direction: row;
    gap: 15px;
    padding: 10px;
}

.side-by-side {
    display: flex;
    flex-direction: row;
    gap: 15px;
    padding: 10px;
}

.vertical-button-group {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.vertical-button-group button {
    // padding: 15px 10px;
}
