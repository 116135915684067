.footer {
    align-items: center;
    gap: 0;
}

.my-vuse-legal-logo {
    padding-bottom: 40px;
}

.my-vuse-legal-logo img {
    max-width: 200px;

    @media screen and (min-width: 490px) {
        max-width: 195px;
    }
}

.disclaimer-paragraphe {
    max-width: 850px;
    padding: 5px 30px;
    color: #ccc;
    text-align: center;
}

.pages-section {
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
}

hr {
    margin: 30px 0px;
    border-color: #ccc;
    width: 100%;

    &#notice-hr {
        margin-bottom: 10px;
    }
}

.policies-list {
    display: flex;
    flex-direction: row;
    // grid-gap: 30px;
    a {
        margin: 0 30px;
    }

    @media screen and (max-width: 490px) {
        flex-wrap: wrap;
        justify-content: center;

        a {
            margin: 0 15px;
        }

        // grid-gap: 15px;
    }
}

.policy-btn {
    color: #a5a5a5 !important;
    letter-spacing: 1.51px;
    cursor: pointer;
    text-decoration: none;
}

.policy-btn:hover {
    text-decoration: underline;
}
