body {
    font-family: "Gotham Book", sans-serif;
    color: #fff;
    font-weight: 400;
    margin: 0;
    padding: 0;
    background: #000;
}

#content-page {
    margin: 0;
    padding: 40px 40px 100px;
    position: relative;
    background: #111;
    font-size: 16px;
    line-height: 20px;
    min-height: 100vh;
}

#content-page div,
#content-page li {
    padding: 4px 0 4px 40px;
}

.footer::before {
    height: 0px !important;
}
