.FeatureBloc {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    color: white;
    padding: 20% 0 0 0;
    //gap: 12px;
}

.feature-icon {
    height: 54px;
    margin-bottom: 5px;
    @media screen and (max-width: 490px) {
        margin: 10px 0 3px;
    }
}

.feature-icon img {
    transition: all ease-in-out 0.2s;
}

.feature-icon img:hover {
    transform: scale(1.2);
}

.feature-title {
    font: normal normal bold 15px/26px Gotham Bold;
    text-transform: capitalize;
    font-size: 0.938rem;
    line-height: 1.625rem;
    @media screen and (max-width: 490px) {
        font-size: 0.875rem;
        line-height: 1.563rem;
    }
}
.feature-description {
    font-size: 0.688rem;
    line-height: 0.938rem;
    max-width: 150px;
    margin-bottom: 0;

    @media screen and (max-width: 490px) {
        font-size: 0.688rem;
        line-height: 0.938rem;
        margin: 5px 0 0;
    }
}

@media screen and (max-width: 500px) {
    .hidden-on-mobile {
        display: none !important;
    }

    .FeatureBloc {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        color: white;
        padding-top: 0;
        padding-bottom: 0;
        gap: 0px;
    }
}
