@font-face {
    font-family: "Gotham Black";
    src: url(./../../fonts/gotham-black-0b52bc5e259f5cc079b29a9e6585b900.woff) format("woff");
    font-style: normal;
}
@font-face {
    font-family: "Gotham Bold";
    src: url(./../../fonts/gotham-bold-6737c42f9e2394e415fcd1518771df26.woff) format("woff");
    font-style: normal;
}
@font-face {
    font-family: "Gotham Medium";
    src: url(./../../fonts/gotham-medium-eec9d76d8a8436dd4528b1ebc87a68fe.woff) format("woff");
    font-style: normal;
}
@font-face {
    font-family: "Gotham Book";
    src: url(./../../fonts/gotham-book-c9d6342d60c821049b4a77146fcf7c0a.woff) format("woff");
}
@font-face {
    font-family: "Poster Trim";
    src: url(./../../fonts/trim-poster-09250a9602fd9a237330b3ed875800ae.woff) format("woff");
}

.footer {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 54px;
    background-color: #1d1d1d;
    color: #9f9f9f;
    font-size: 11px;
    gap: 50px;
    width: 100%;
    z-index: 10;

    hr {
        margin: 30px 0;
        border-color: #707070;
        width: 100%;

        @media screen and (max-width: 490px) {
            width: 280px;
        }
    }
    @media screen and (min-width: 490px) {
        padding-bottom: 25px;
    }

    @media screen and (min-width: 490px) {
        padding-bottom: 25px;

        .my-vuse-logo {
            padding-bottom: 50px;
        }

        .horizontal-line {
            display: none;
        }

        .my-vuse-logo hr {
            display: none;
        }
    }
}

.my-vuse-logo {
    display: flex;
    flex-direction: column;
    gap: 19px;
}

.my-vuse-logo::after {
    border: 1px solid white;
    width: 100%;
}

.my-vuse-logo-subtitle {
    text-align: center;
    color: #707070;
    font-size: 9px;
    letter-spacing: 9px;
}

.my-vuse-logo img {
    max-width: 190px;

    @media screen and (min-width: 490px) {
        max-width: 300px;
    }
}

.contact-us-group {
    font-family: "Gotham Bold", sans-serif;
    letter-spacing: 1.2px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: center;
}

.contact-us {
    font-size: 12px;
    font-weight: 600;
    color: white;
}

.contact-us-subtitle {
    font-family: "Gotham book", sans-serif;
    font-size: 10px;
    letter-spacing: 1px;
    color: #a9a9a9;
    text-align: center;
}

.phone-number {
    font-family: "Poster Trim", Helvetica, sans-serif;
    color: white;
    border: 1px solid #fd8438;
    border-radius: 28px;
    padding: 5px 21px;
    font-size: 26px;
    font-weight: 600;
    display: flex;
    flex-direction: row;
    align-items: center;

    a {
        text-decoration: none;
        margin-left: 10px;
    }
}

.footers {
    background-color: #1d1d1d;
    position: relative;

    &:before {
        content: "";
        background-color: #1d1d1d;
        position: absolute;
        top: -97px;
        height: 100px;
        z-index: 9;
        left: 0;
        right: 0;
    }

    .footer {
        padding: 0;
    }
    .footer-top {
        padding: 55px 0 0 0;
    }
}
