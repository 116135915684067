body {
    font-family: "Gotham Book", sans-serif;
    color: #fff;
    font-weight: 400;
    margin: 0;
    padding: 0;
    background: #000;
}

#policy {
    margin: 0;
    padding: 40px 40px 100px;
    position: relative;
    background: #111;
    font-size: 16px;
    line-height: 20px;
    min-height: 100vh;
}

#policy div,
#policy li {
    padding: 4px 0 4px 40px;
}

.square {
    border: 1px solid white;
    padding: 15px;
}

a:link {
    color: white;
}

table {
    width: 100%;
    border: 2px solid white;
    border-collapse: collapse;
    vertical-align: baseline;
    padding: 4px;
}

table b {
    padding-bottom: 5px;
}

table th {
    border-right: 1px solid white;
}
table td {
    border: 2px solid white;
    padding: 10px;
}

.footer::before {
    height: 0px !important;
}
